import React from 'react'
import styles from './ToolsSection.module.scss'
import { Container } from 'components/common/Containers/Container/Container'
import { PageLabel } from 'components/common/Texts/PageLabel/PageLabel'
import { H2 } from 'components/common/Texts/H2/H2'
import { FlexRow } from 'components/common/Containers/FlexRow/FlexRow'
import { Button } from 'components/common/Buttons/Button'
import { Flex } from 'components/common/Containers/Flex/Flex'
import { FlexColumn } from 'components/common/Containers/FlexColumn/FlexColumn'
import Image from 'next/image'
import { H3 } from 'components/common/Texts/H3/H3'
import ToolsData from './tools-data.json'
import classnames from 'classnames'

interface Props {}

export const ToolsSection: React.FC<Props> = ({}) => {
  const [activeId, setActiveId] = React.useState(0)

  const activeTab = ToolsData[activeId]

  const onNext = React.useCallback(() => {
    setActiveId((activeId) => (activeId + 1) % ToolsData.length)
  }, [setActiveId])

  const onPrev = React.useCallback(() => {
    setActiveId(
      (activeId) => (activeId + ToolsData.length - 1) % ToolsData.length
    )
  }, [setActiveId])

  return (
    <Container className={styles.container}>
      <PageLabel>Big Toolset</PageLabel>
      <H2 className={styles.heading}>Most popular tools</H2>
      <FlexRow className={styles.tabWrapper} gap="10px">
        {ToolsData.map((tab) => (
          <Button
            key={tab.id}
            onClick={() => setActiveId(tab.id)}
            mode={activeId === tab.id ? 'primary' : 'text'}
          >
            {tab.tabName}
          </Button>
        ))}
      </FlexRow>
      <FlexRow className={styles.contentWrapper}>
        <FlexColumn data-aos="fade-right" className={styles.container}>
          <FlexRow className={styles.head}>
            <Image
              height={69}
              width={85}
              alt="icon"
              src="/images/home/tools/lens.svg"
            />
            <H3>{activeTab.head}</H3>
          </FlexRow>
          <p>{activeTab.content}</p>
          <FlexRow className={styles.arrows}>
            <Image
              className={styles.left}
              height={21}
              width={70}
              alt="arrow-left"
              src="/images/home/tools/arrow.svg"
              onClick={onPrev}
            />
            <Image
              className={styles.right}
              height={21}
              width={70}
              alt="arrow-right"
              src="/images/home/tools/arrow.svg"
              onClick={onNext}
            />
          </FlexRow>
        </FlexColumn>
        <Flex
          data-aos="fade-left"
          className={classnames(styles.container, styles.rightContainer)}
        >
          <div className={styles.largeImageContainer}>
            <Image height={441} width={619} alt="image" src={activeTab.src} />
          </div>
        </Flex>
      </FlexRow>
    </Container>
  )
}
