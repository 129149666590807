import React from 'react'
import styles from './DomainBackorderingSection.module.scss'
import { Container } from 'components/common/Containers/Container/Container'
import { FlexColumn } from 'components/common/Containers/FlexColumn/FlexColumn'
import { PageLabel } from 'components/common/Texts/PageLabel/PageLabel'
import { H2 } from 'components/common/Texts/H2/H2'
import NextImage from 'next/image'
import { Flex } from 'components/common/Containers/Flex/Flex'
import { Button } from 'components/common/Buttons/Button'
import Link from 'next/link'
import { RouteEnum } from "../../../enums/RouteEnum";

interface Props {}

export const DomainBackorderingSection: React.FC<Props> = ({}) => {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <Flex className={styles.left} data-aos="fade-right">
          <div className={styles.leftImageContainer}>
            <NextImage
              alt={'domain-backorder'}
              src="/images/home/domain-backorder.svg"
              width={556}
              height={474}
            />
          </div>
        </Flex>

        <FlexColumn className={styles.right} data-aos="fade-left">
          <PageLabel>We make it simplified</PageLabel>
          <H2>Domain Backordering Simplified</H2>
          <p>
          We understand how hard it can be to get high-potential domains as they drop. Bishopi&apos;s prompt database helps you to backorder domains before competitors. 
          To spice up the deal, you don&apos;t have to part with an arm and a leg to secure your domain investment. No upfront fees, no hidden charges.
          </p>
          <p>
          Create a free account and backorder as many domains as you want. 
          If more than one person backorders a domain, we create a <strong>10-day auction</strong>. 
          If you are the only bidder, you get the name at an unmatched price.
          </p>
          <Link passHref href={RouteEnum.DOMAINS}>
          <Button mode="text" className={styles.underline}>
            Learn More
          </Button>
          </Link>
        </FlexColumn>
      </Container>
    </section>
  )
}

