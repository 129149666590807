import React, { useMemo } from 'react'
import styles from './AnimatedH1.module.scss'
import classNames from 'classnames'
import { H1 } from 'components/common/Texts/H1/H1'

interface Props {}

export const AnimatedH1: React.FC<Props> = ({}) => {
  const [visibleIndex, setVisibleIndex] = React.useState(0)
  const items = useMemo(
    () => ['Domain Investors','Digital Marketers', 'SEO Professionals'],
    []
  )

  React.useEffect(() => {
    const ANIMATION_DELAY = 3 * 1000 // 3 seconds
    const timeout = setInterval(() => {
      setVisibleIndex((index) => {
        const nextIndex = (index + 1) % items.length
        return nextIndex
      })
    }, ANIMATION_DELAY)

    return () => {
      clearInterval(timeout)
    }
  }, [items])

  return (
    <>
      <H1>
        #1 Domain Tools for
        <ul className={styles.animated}>
          {items.map((item, i) => {
            return (
              <li
                key={item}
                className={classNames(
                  styles.tile,
                  visibleIndex === i && styles.visible
                )}
              >
                {item}
              </li>
            )
          })}
        </ul>
      </H1>
      <div className={styles.lineContainer}>
        <div className={styles.line} />
      </div>
    </>
  )
}
